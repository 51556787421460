@font-face {
  font-family: 'Quicksand';
  src: url('quicksand.ttf');
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #b810c3;
}

.title {
  color: #f0f0f0;
  font-size: 3.5rem;
  font-family: 'Quicksand';
}

.label {
  color: #f0f0f0;
  font-size: 2rem;
  font-family: 'Quicksand';
}

.error {
  color: #e54b4b;
  font-size: 1.5rem;
  font-family: 'Quicksand';
}

input {
  width: 40vw;
  padding: 12px 20px;
  margin: 8px 0;
  font-family: 'Quicksand';
  display: inline-block;
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
}

button {
  background-color: #b810c3;
  color: #f0f0f0;
  font-family: 'Quicksand';
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 20vw;
  border-radius: 15px;
  box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 7px 12px -8px rgba(0, 0, 0, 0.75);
}
